<template>
    <div class="test">
        <span>跳转测试页面</span>
        <el-button type="primary" size="small" @click="onBack">返 回</el-button>
    </div>
</template>

<script>
    export default {
        mounted() {
            this.$message.success('触发生命周期mounted', 3000)
        },

        methods: {
            onBack(){
                this.$router.go(-1)
            }
        },
    }
</script>

<style lang="less" scoped>
.test{
    .flex-col;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    >span{
        margin: 10px 0;
    }
}
</style>